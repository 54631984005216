import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content, { HTMLContent } from "../components/Content"

export class ProjectPostItem extends React.Component {
  render() {
    const { title, description, html, htmlComponent } = this.props
    const PostContent = htmlComponent || Content
    return (
      <article className={`post-content no-image`}>
        <header className="post-content-header">
          <h1 className="post-content-title">{title}</h1>
        </header>

        {description && <p className="post-content-excerpt">{description}</p>}

        <PostContent content={html}/>
        <footer className="post-content-footer">
        </footer>
      </article>
    )
  }
}

class ProjectPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    // console.dir(post.frontmatter)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.thumbnail.childImageSharp.fluid.src}
        />
        <ProjectPostItem
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          html={post.html}
          htmlComponent={HTMLContent}
        />
      </Layout>
    )
  }
}

export default ProjectPostTemplate

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
         date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  base64
                  src
                }
              }
            }        
      }
    }
  }
`
