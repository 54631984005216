import React from "react"
import styled from "styled-components"

const Container = styled.div`
  .css-yilgkd-WidgetPreviewContainer > div img {
    max-width: 100%;
  } 
`

export const HTMLContent = ({ content }) => (
  <div
    className="post-content-body"
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

const Content = ({ content, className }) => (
  <Container>
    <div className={className}>{content}</div>
  </Container>
)

export default Content
